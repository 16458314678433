import { ArrowUpward } from "@mui/icons-material";
import { Box, Container, Grid } from "@mui/material";
import React, { Fragment, useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { LayoutContext, TypographyWithIcon, newGuid } from "wcz-layout";
import { ReleaseCard } from "../components/release/ReleaseCard";
import { useSearchRelease } from "../queries/ReleaseQueries";

const TAKE = 10;

export const ReleasesPage: React.FC = () => {
    const { changeTitle, t, i18n } = useContext(LayoutContext);
    const { ref, inView } = useInView();

    useEffect(() => changeTitle(t("Announcements")), [i18n.resolvedLanguage]);

    const { data, fetchNextPage, hasNextPage } = useSearchRelease({ take: TAKE }, {
        getNextPageParam: (lastPage, allPages) => lastPage.releases.length === TAKE ? allPages.length + 1 : undefined
    });

    useEffect(() => {
        if (inView && hasNextPage)
            fetchNextPage();
    }, [inView, hasNextPage]);

    return (
        <Container maxWidth="xl" sx={{ my: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ float: "right" }}>
                        <TypographyWithIcon endIcon={<ArrowUpward fontSize="small" sx={{ opacity: 0.6 }} />} color="text.secondary">{t("Newest")}</TypographyWithIcon>
                    </Box>
                </Grid>

                {data?.pages.map(page =>
                    <Fragment key={newGuid()}>
                        {page.releases.map(release =>
                            <Grid item xs={12} key={release.id}>
                                <ReleaseCard release={release} />
                            </Grid>
                        )}
                    </Fragment>
                )}
            </Grid>

            <div ref={ref}></div>
        </Container>
    );
};